import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";
import ConvertKitForm from "convertkit-react";

import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Author from "../components/Author"

export const BlogPostTemplate = ({
  author,
  content,
  contentComponent,
  date,
  updated,
  description,
  tags,
  title,
  helmet,
  readingTime,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <h6>
              <span>Last Updated: {updated}</span> •{" "}
              <span>{readingTime.text}</span>
            </h6>
            {/* <h6>
              <span>Author: {author}</span>
            </h6> */}
            <p className="disclosure">
              Disclosure: This page may contain affiliate links and we may receive
              a commission through them, but this is at no additional cost to
              you. For more information, please read our
              <Link to={"/privacy-policy"}> privacy policy.</Link>
            </p>
            {/* <p>{description}</p> */}
            <Author author={author} />
            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
                <h4>Tags</h4>
                <ul className="taglist">
                  {tags.map((tag) => (
                    <li key={tag + `tag`}>
                      <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
        <ConvertKitForm
          formId={6053038}
          template="mills"
          emailPlaceholder="Email Address"
          submitText="Get My Updates"
          format="modal"
          headingText="Join the Newsletter"
          hideName={true}
          buttonBackground="#008307"
          className="ck-fm"
          >
          <p>Subscribe to get our latest content by email.</p>
        </ConvertKitForm>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  author: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  date: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  readingTime: PropTypes.object,
  updated: PropTypes.string
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogPostTemplate
        author={post.frontmatter.author}
        content={post.html}
        contentComponent={HTMLContent}
        updated={post.frontmatter.updated}
        date={post.frontmatter.date}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.title}`}</title>
            {/* <link
              rel="canonical"
              href={`${withPrefix("/")}path-to-article-somehow`}
            /> */}
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              name="og:image"
              content={`${data.site.siteMetadata.siteUrl}${post.frontmatter.featuredimage.childImageSharp.original.src}`}
            />
            {/* <meta
              name="og:image:width"
              content={post.frontmatter.featured.childImageSharp.gatsbyImage.width}
            />
            <meta
              name="og:image:height"
              content={post.frontmatter.featured.childImageSharp.gatsbyImage.height}
            /> */}
            {/* <meta name="og:image:type" /> */}
            <meta
              name="robots"
              content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
            />
            <meta property="og:site_name" content="Pineapple Money" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content={post.frontmatter.title} />
            <meta property="article:published_time" content={post.frontmatter.date} />
            <meta property="article:modified_time" content={post.frontmatter.updated} />
            {/* <meta property="og:url" content={} */}
            <script type="application/ld+json">
              {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "BlogPosting",
                // mainEntityOfPage: {
                //   "@type": "WebPage",
                //   "@id": `https://codesandbox.io/s/competent-darkness-7m3h7?file=/src/StructuredData.js`
                // },
                headline: `${post.frontmatter.title}`,
                description: `${post.frontmatter.description}`,
                // image: `${post.frontmatter.featuredimage}`,
                author: {
                  "@type": "Person",
                  name: `${post.frontmatter.author}`
                },
                publisher: {
                  "@type": "Organization",
                  name: `Pineapple Money`,
                  // logo: {
                  //   "@type": "ImageObject",
                  //   url:
                  //     "https://images.ctfassets.net/z7pyyvho21dm/56G3Sy04rYABwiRJE0Qpd5/93c58230cd554417689228cab8a4f077/Screenshot_2021-04-26_132720.png?w=680&h=144&q=50&fm=webp"
                  // }
                },
                datePublished: `${post.frontmatter.date}`,
                dateModified: `${post.frontmatter.updated}`
              })}
            </script>
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        readingTime={post.fields.readingTime}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        updated(formatString: "MMMM DD, YYYY")
        author
        title
        description
        tags
        featuredimage {
          childImageSharp {
            original {
              src
            }
          }
        }
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
  }
`;
